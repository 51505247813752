/** @format */
import { getStageURL } from "./utils.js";

export const SERVER_DOMAIN = getStageURL(window);

export const countryCode = [
  { name: "Alaska", countryCode: "us" },
  { name: "Afghanistan", countryCode: "af" },
  { name: "Aland Islands", countryCode: "ax" },
  { name: "Albania", countryCode: "al" },
  { name: "Algeria", countryCode: "dz" },
  { name: "American Samoa", countryCode: "as" },
  { name: "Andorra", countryCode: "ad" },
  { name: "Angola", countryCode: "ao" },
  { name: "Anguilla", countryCode: "ai" },
  { name: "Antigua", countryCode: "ag" },
  { name: "Argentina", countryCode: "ar" },
  { name: "Armenia", countryCode: "am" },
  { name: "Aruba", countryCode: "aw" },
  { name: "Australia", countryCode: "au" },
  { name: "Austria", countryCode: "at" },
  { name: "Azerbaijan", countryCode: "az" },
  { name: "Bahamas", countryCode: "bs" },
  { name: "Bahrain", countryCode: "bh" },
  { name: "Bangladesh", countryCode: "bd" },
  { name: "Barbados", countryCode: "bb" },
  { name: "Belarus", countryCode: "by" },
  { name: "Belgium", countryCode: "be" },
  { name: "Belize", countryCode: "bz" },
  { name: "Benin", countryCode: "bj" },
  { name: "Bermuda", countryCode: "bm" },
  { name: "Bhutan", countryCode: "bt" },
  { name: "Bolivia", countryCode: "bo" },
  { name: "Bosnia", countryCode: "ba" },
  { name: "Bosnia-Herzegovina", countryCode: "ba" },
  { name: "Botswana", countryCode: "bw" },
  { name: "Bouvet Island", countryCode: "bv" },
  { name: "Brazil", countryCode: "br" },
  { name: "British Virgin Islands", countryCode: "vg" },
  { name: "Brunei", countryCode: "bn" },
  { name: "Bulgaria", countryCode: "bg" },
  { name: "Burkina Faso", countryCode: "bf" },
  { name: "Burma", countryCode: "mm", alias: "Myanmar" },
  { name: "Burundi", countryCode: "bi" },
  { name: "Caicos Islands", countryCode: "tc" },
  { name: "Cambodia", countryCode: "kh" },
  { name: "Cameroon", countryCode: "cm" },
  { name: "Canada", countryCode: "ca" },
  { name: "Cape Verde", countryCode: "cv" },
  { name: "Cayman Islands", countryCode: "ky" },
  { name: "Central African Republic", countryCode: "cf" },
  { name: "Chad", countryCode: "td" },
  { name: "Chile", countryCode: "cl" },
  { name: "China", countryCode: "cn" },
  { name: "Christmas Island", countryCode: "cx" },
  { name: "Cocos Islands", countryCode: "cc" },
  { name: "Colombia", countryCode: "co" },
  { name: "Comoros", countryCode: "km" },
  { name: "Congo", countryCode: "cd" },
  { name: "Congo Brazzaville", countryCode: "cg" },
  { name: "Cook Islands", countryCode: "ck" },
  { name: "Costa Rica", countryCode: "cr" },
  { name: "Cote Divoire", countryCode: "ci" },
  { name: "Croatia", countryCode: "hr" },
  { name: "Cuba", countryCode: "cu" },
  { name: "Cyprus", countryCode: "cy" },
  { name: "Czech Republic", countryCode: "cz" },
  { name: "Denmark", countryCode: "dk" },
  { name: "Djibouti", countryCode: "dj" },
  { name: "Dominica", countryCode: "dm" },
  { name: "Dominican Republic", countryCode: "do" },
  { name: "Ecuador", countryCode: "ec" },
  { name: "Egypt", countryCode: "eg" },
  { name: "El Salvador", countryCode: "sv" },
  { name: "England", countryCode: "GB_ENG" },
  { name: "Equatorial Guinea", countryCode: "gq" },
  { name: "Eritrea", countryCode: "er" },
  { name: "Estonia", countryCode: "ee" },
  { name: "Ethiopia", countryCode: "et" },
  { name: "Europeanunion", countryCode: "eu" },
  { name: "Falkland Islands", countryCode: "fk" },
  { name: "Faroe Islands", countryCode: "fo" },
  { name: "Fiji", countryCode: "fj" },
  { name: "Finland", countryCode: "fi" },
  { name: "France", countryCode: "fr" },
  { name: "French Guiana", countryCode: "gf" },
  { name: "French Polynesia", countryCode: "pf" },
  { name: "French Territories", countryCode: "tf" },
  { name: "Gabon", countryCode: "ga" },
  { name: "Gambia", countryCode: "gm" },
  { name: "Georgia", countryCode: "ge" },
  { name: "Germany", countryCode: "de" },
  { name: "Ghana", countryCode: "gh" },
  { name: "Gibraltar", countryCode: "gi" },
  { name: "Greece", countryCode: "gr" },
  { name: "Greenland", countryCode: "gl" },
  { name: "Grenada", countryCode: "gd" },
  { name: "Guadeloupe", countryCode: "gp" },
  { name: "Guam", countryCode: "gu" },
  { name: "Guatemala", countryCode: "gt" },
  { name: "Guinea", countryCode: "gn" },
  { name: "Guinea-Bissau", countryCode: "gw" },
  { name: "Guyana", countryCode: "gy" },
  { name: "Haiti", countryCode: "ht" },
  { name: "Heard Island", countryCode: "hm" },
  { name: "Honduras", countryCode: "hn" },
  { name: "Hong Kong", countryCode: "hk" },
  { name: "Hungary", countryCode: "hu" },
  { name: "Iceland", countryCode: "is" },
  { name: "India", countryCode: "in" },
  { name: "Indian Ocean Territory", countryCode: "io" },
  { name: "Indonesia", countryCode: "id" },
  { name: "Iran", countryCode: "ir" },
  { name: "Iraq", countryCode: "iq" },
  { name: "Ireland", countryCode: "ie" },
  { name: "Israel", countryCode: "il" },
  { name: "Italy", countryCode: "it" },
  { name: "Jamaica", countryCode: "jm" },
  { name: "Jan Mayen", countryCode: "sj", alias: "Svalbard" },
  { name: "Japan", countryCode: "jp" },
  { name: "Jordan", countryCode: "jo" },
  { name: "Kazakhstan", countryCode: "kz" },
  { name: "Kenya", countryCode: "ke" },
  { name: "Kiribati", countryCode: "ki" },
  { name: "Kuwait", countryCode: "kw" },
  { name: "Kyrgyzstan", countryCode: "kg" },
  { name: "Laos", countryCode: "la" },
  { name: "Latvia", countryCode: "lv" },
  { name: "Lebanon", countryCode: "lb" },
  { name: "Lesotho", countryCode: "ls" },
  { name: "Liberia", countryCode: "lr" },
  { name: "Libya", countryCode: "ly" },
  { name: "Liechtenstein", countryCode: "li" },
  { name: "Lithuania", countryCode: "lt" },
  { name: "Luxembourg", countryCode: "lu" },
  { name: "Macau", countryCode: "mo" },
  { name: "Macedonia", countryCode: "mk" },
  { name: "Madagascar", countryCode: "mg" },
  { name: "Malawi", countryCode: "mw" },
  { name: "Malaysia", countryCode: "my" },
  { name: "Maldives", countryCode: "mv" },
  { name: "Mali", countryCode: "ml" },
  { name: "Malta", countryCode: "mt" },
  { name: "Marshall Islands", countryCode: "mh" },
  { name: "Martinique", countryCode: "mq" },
  { name: "Mauritania", countryCode: "mr" },
  { name: "Mauritius", countryCode: "mu" },
  { name: "Mayotte", countryCode: "yt" },
  { name: "Mexico", countryCode: "mx" },
  { name: "Micronesia", countryCode: "fm" },
  { name: "Moldova", countryCode: "md" },
  { name: "Monaco", countryCode: "mc" },
  { name: "Mongolia", countryCode: "mn" },
  { name: "Montenegro", countryCode: "me" },
  { name: "Montserrat", countryCode: "ms" },
  { name: "Morocco", countryCode: "ma" },
  { name: "Mozambique", countryCode: "mz" },
  { name: "Namibia", countryCode: "na" },
  { name: "Nauru", countryCode: "nr" },
  { name: "Nepal", countryCode: "np" },
  { name: "Netherlands", countryCode: "nl" },
  { name: "Netherlandsantilles", countryCode: "an" },
  { name: "New Caledonia", countryCode: "nc" },
  { name: "New Guinea", countryCode: "pg" },
  { name: "New Zealand", countryCode: "nz" },
  { name: "Nicaragua", countryCode: "ni" },
  { name: "Niger", countryCode: "ne" },
  { name: "Nigeria", countryCode: "ng" },
  { name: "Niue", countryCode: "nu" },
  { name: "Norfolk Island", countryCode: "nf" },
  { name: "North Korea", countryCode: "kp" },
  { name: "Northern Mariana Islands", countryCode: "mp" },
  { name: "Norway", countryCode: "no" },
  { name: "Oman", countryCode: "om" },
  { name: "Pakistan", countryCode: "pk" },
  { name: "Palau", countryCode: "pw" },
  { name: "Palestine", countryCode: "ps" },
  { name: "Panama", countryCode: "pa" },
  { name: "Paraguay", countryCode: "py" },
  { name: "Peru", countryCode: "pe" },
  { name: "Philippines", countryCode: "ph" },
  { name: "Pitcairn Islands", countryCode: "pn" },
  { name: "Poland", countryCode: "pl" },
  { name: "Portugal", countryCode: "pt" },
  { name: "Puerto Rico", countryCode: "pr" },
  { name: "Qatar", countryCode: "qa" },
  { name: "Reunion", countryCode: "re" },
  { name: "Romania", countryCode: "ro" },
  { name: "Russia", countryCode: "ru" },
  { name: "Russia (Asiatic)", countryCode: "ru" },
  { name: "Rwanda", countryCode: "rw" },
  { name: "Saint Helena", countryCode: "sh" },
  { name: "Saint Kitts and Nevis", countryCode: "kn" },
  { name: "Saint Lucia", countryCode: "lc" },
  { name: "Saint Pierre", countryCode: "pm" },
  { name: "Saint Vincent", countryCode: "vc" },
  { name: "Samoa", countryCode: "ws" },
  { name: "San Marino", countryCode: "sm" },
  { name: "Sandwich Islands", countryCode: "gs" },
  { name: "Sao Tome", countryCode: "st" },
  { name: "Saudi Arabia", countryCode: "sa" },
  { name: "Scotland", countryCode: "GB_SCT" },
  { name: "Senegal", countryCode: "sn" },
  { name: "Serbia", countryCode: "cs" },
  { name: "Serbia", countryCode: "rs" },
  { name: "Seychelles", countryCode: "sc" },
  { name: "Sierra Leone", countryCode: "sl" },
  { name: "Singapore", countryCode: "sg" },
  { name: "Slovakia", countryCode: "sk" },
  { name: "Slovenia", countryCode: "si" },
  { name: "Solomon Islands", countryCode: "sb" },
  { name: "Somalia", countryCode: "so" },
  { name: "South Africa", countryCode: "za" },
  { name: "South Korea", countryCode: "kr" },
  { name: "Spain", countryCode: "es" },
  { name: "Sri Lanka", countryCode: "lk" },
  { name: "Sudan", countryCode: "sd" },
  { name: "Suriname", countryCode: "sr" },
  { name: "Swaziland", countryCode: "sz" },
  { name: "Sweden", countryCode: "se" },
  { name: "Switzerland", countryCode: "ch" },
  { name: "Syria", countryCode: "sy" },
  { name: "Taiwan", countryCode: "tw" },
  { name: "Tajikistan", countryCode: "tj" },
  { name: "Tanzania", countryCode: "tz" },
  { name: "Thailand", countryCode: "th" },
  { name: "Timorleste", countryCode: "tl" },
  { name: "Togo", countryCode: "tg" },
  { name: "Tokelau", countryCode: "tk" },
  { name: "Tonga", countryCode: "to" },
  { name: "Trinidad", countryCode: "tt" },
  { name: "Tunisia", countryCode: "tn" },
  { name: "Turkey", countryCode: "tr" },
  { name: "Turkmenistan", countryCode: "tm" },
  { name: "Tuvalu", countryCode: "tv" },
  { name: "U.A.E.", countryCode: "ae", alias: "United Arab Emirates" },
  { name: "Uganda", countryCode: "ug" },
  { name: "Ukraine", countryCode: "ua" },
  { name: "United Kingdom", countryCode: "gb", alias: "uk" },
  { name: "United States", countryCode: "us", alias: "America" },
  { name: "United States of America", countryCode: "us", alias: "America" },
  { name: "Uruguay", countryCode: "uy" },
  { name: "US Minor Islands", countryCode: "um" },
  { name: "US Virgin Islands", countryCode: "vi" },
  { name: "Uzbekistan", countryCode: "uz" },
  { name: "Vanuatu", countryCode: "vu" },
  { name: "Vatican City", countryCode: "va" },
  { name: "Venezuela", countryCode: "ve" },
  { name: "Vietnam", countryCode: "vn" },
  { name: "Wales", countryCode: "GB_WLS" },
  { name: "Wallis and Futuna", countryCode: "wf" },
  { name: "Western Sahara", countryCode: "eh" },
  { name: "Yemen", countryCode: "ye" },
  { name: "Zambia", countryCode: "zm" },
  { name: "Zimbabwe", countryCode: "zw" },
];

export const canPrefix = [
  {
    prefix: "VO1",
    name: "Newfoundland",
    latitude: "48.9544",
    longitude: "-54.6103",
  },
  {
    prefix: "VO2",
    name: "Labrador",
    latitude: "53.1355",
    longitude: "-57.6604",
  },
  {
    prefix: "VA5",
    name: "Saskatchewan",
    latitude: "55",
    longitude: "-106",
  },
  {
    prefix: "VE5",
    name: "Saskatchewan",
    latitude: "55",
    longitude: "-106",
  },
  {
    prefix: "VY2",
    name: "Prince Edward Island",
    latitude: "46.25",
    longitude: "-63",
  },
  {
    prefix: "VA3",
    name: "Ontario",
    latitude: "50",
    longitude: "-85",
  },
  {
    prefix: "VE3",
    name: "Ontario",
    latitude: "50",
    longitude: "-85",
  },
  {
    prefix: "VA1",
    name: "Nova Scotia",
    latitude: "45",
    longitude: "-63",
  },
  {
    prefix: "VE1",
    name: "Nova Scotia",
    latitude: "45",
    longitude: "-63",
  },
  {
    prefix: "VA6",
    name: "Alberta",
    latitude: "55",
    longitude: "-115",
  },
  {
    prefix: "VE6",
    name: "Alberta",
    latitude: "55",
    longitude: "-115",
  },
  {
    prefix: "VA7",
    name: "British Columbia",
    latitude: "53.726669",
    longitude: "-127.647621",
  },
  {
    prefix: "VE7",
    name: "British Columbia",
    latitude: "53.726669",
    longitude: "-127.647621",
  },
  {
    prefix: "VA4",
    name: "Manitoba",
    latitude: "56.415211",
    longitude: "-98.739075",
  },
  {
    prefix: "VE4",
    name: "Manitoba",
    latitude: "56.415211",
    longitude: "-98.739075",
  },
  {
    prefix: "VE9",
    name: "New Brunswick",
    latitude: "46.49839",
    longitude: "-66.159668",
  },
  {
    prefix: "VA2",
    name: "Quebec",
    latitude: "53",
    longitude: "-70",
  },
  {
    prefix: "VE2",
    name: "Quebec",
    latitude: "53",
    longitude: "-70",
  },
  {
    prefix: "VE8",
    name: "Northwest Territories",
    latitude: "64.8255",
    longitude: "-124.8457",
  },
  {
    prefix: "VY1",
    name: "Yukon",
    latitude: "64.2823",
    longitude: "-135",
  },
  {
    prefix: "VY0",
    name: "Nunavut",
    latitude: "70.2998",
    longitude: "-83.1076",
  },
];

export const areaCode = [
  {
    area: "0",
    state: "NE",
  },
  {
    area: "1",
    state: "NH",
  },
  {
    area: "2",
    state: "NY",
  },
  {
    area: "3",
    state: "PA",
  },
  {
    area: "4",
    state: "GA",
  },
  {
    area: "5",
    state: "TX",
  },
  {
    area: "6",
    state: "CA",
  },
  {
    area: "7",
    state: "UT",
  },
  {
    area: "8",
    state: "OH",
  },
  {
    area: "9",
    state: "IL",
  },
];

export const gps = [
  {
    country_code: "AD",
    latitude: "42.546245",
    longitude: "1.601554",
    country: "Andorra",
    usa_state_code: "AK",
    usa_state_latitude: "63.588753",
    usa_state_longitude: "-154.493062",
    usa_state: "Alaska",
  },
  {
    country_code: "AE",
    latitude: "23.424076",
    longitude: "53.847818",
    country: "United Arab Emirates",
    usa_state_code: "AL",
    usa_state_latitude: "32.318231",
    usa_state_longitude: "-86.902298",
    usa_state: "Alabama",
  },
  {
    country_code: "AF",
    latitude: "33.93911",
    longitude: "67.709953",
    country: "Afghanistan",
    usa_state_code: "AR",
    usa_state_latitude: "35.20105",
    usa_state_longitude: "-91.831833",
    usa_state: "Arkansas",
  },
  {
    country_code: "AG",
    latitude: "17.060816",
    longitude: "-61.796428",
    country: "Antigua and Barbuda",
    usa_state_code: "AZ",
    usa_state_latitude: "34.048928",
    usa_state_longitude: "-111.093731",
    usa_state: "Arizona",
  },
  {
    country_code: "AI",
    latitude: "18.220554",
    longitude: "-63.068615",
    country: "Anguilla",
    usa_state_code: "CA",
    usa_state_latitude: "36.778261",
    usa_state_longitude: "-119.417932",
    usa_state: "California",
  },
  {
    country_code: "AL",
    latitude: "41.153332",
    longitude: "20.168331",
    country: "Albania",
    usa_state_code: "CO",
    usa_state_latitude: "39.550051",
    usa_state_longitude: "-105.782067",
    usa_state: "Colorado",
  },
  {
    country_code: "AM",
    latitude: "40.069099",
    longitude: "45.038189",
    country: "Armenia",
    usa_state_code: "CT",
    usa_state_latitude: "41.603221",
    usa_state_longitude: "-73.087749",
    usa_state: "Connecticut",
  },
  {
    country_code: "AN",
    latitude: "12.226079",
    longitude: "-69.060087",
    country: "Netherlands Antilles",
    usa_state_code: "DC",
    usa_state_latitude: "38.905985",
    usa_state_longitude: "-77.033418",
    usa_state: "District of Columbia",
  },
  {
    country_code: "AO",
    latitude: "-11.202692",
    longitude: "17.873887",
    country: "Angola",
    usa_state_code: "DE",
    usa_state_latitude: "38.910832",
    usa_state_longitude: "-75.52767",
    usa_state: "Delaware",
  },
  {
    country_code: "AQ",
    latitude: "-75.250973",
    longitude: "-0.071389",
    country: "Antarctica",
    usa_state_code: "FL",
    usa_state_latitude: "27.664827",
    usa_state_longitude: "-81.515754",
    usa_state: "Florida",
  },
  {
    country_code: "AR",
    latitude: "-38.416097",
    longitude: "-63.616672",
    country: "Argentina",
    usa_state_code: "GA",
    usa_state_latitude: "32.157435",
    usa_state_longitude: "-82.907123",
    usa_state: "Georgia",
  },
  {
    country_code: "AS",
    latitude: "-14.270972",
    longitude: "-170.132217",
    country: "American Samoa",
    usa_state_code: "HI",
    usa_state_latitude: "19.898682",
    usa_state_longitude: "-155.665857",
    usa_state: "Hawaii",
  },
  {
    country_code: "AT",
    latitude: "47.516231",
    longitude: "14.550072",
    country: "Austria",
    usa_state_code: "IA",
    usa_state_latitude: "41.878003",
    usa_state_longitude: "-93.097702",
    usa_state: "Iowa",
  },
  {
    country_code: "AU",
    latitude: "-25.274398",
    longitude: "133.775136",
    country: "Australia",
    usa_state_code: "ID",
    usa_state_latitude: "44.068202",
    usa_state_longitude: "-114.742041",
    usa_state: "Idaho",
  },
  {
    country_code: "AW",
    latitude: "12.52111",
    longitude: "-69.968338",
    country: "Aruba",
    usa_state_code: "IL",
    usa_state_latitude: "40.633125",
    usa_state_longitude: "-89.398528",
    usa_state: "Illinois",
  },
  {
    country_code: "AZ",
    latitude: "40.143105",
    longitude: "47.576927",
    country: "Azerbaijan",
    usa_state_code: "IN",
    usa_state_latitude: "40.551217",
    usa_state_longitude: "-85.602364",
    usa_state: "Indiana",
  },
  {
    country_code: "BA",
    latitude: "43.915886",
    longitude: "17.679076",
    country: "Bosnia and Herzegovina",
    usa_state_code: "KS",
    usa_state_latitude: "39.011902",
    usa_state_longitude: "-98.484246",
    usa_state: "Kansas",
  },
  {
    country_code: "BB",
    latitude: "13.193887",
    longitude: "-59.543198",
    country: "Barbados",
    usa_state_code: "KY",
    usa_state_latitude: "37.839333",
    usa_state_longitude: "-84.270018",
    usa_state: "Kentucky",
  },
  {
    country_code: "BD",
    latitude: "23.684994",
    longitude: "90.356331",
    country: "Bangladesh",
    usa_state_code: "LA",
    usa_state_latitude: "31.244823",
    usa_state_longitude: "-92.145024",
    usa_state: "Louisiana",
  },
  {
    country_code: "BE",
    latitude: "50.503887",
    longitude: "4.469936",
    country: "Belgium",
    usa_state_code: "MA",
    usa_state_latitude: "42.407211",
    usa_state_longitude: "-71.382437",
    usa_state: "Massachusetts",
  },
  {
    country_code: "BF",
    latitude: "12.238333",
    longitude: "-1.561593",
    country: "Burkina Faso",
    usa_state_code: "MD",
    usa_state_latitude: "39.045755",
    usa_state_longitude: "-76.641271",
    usa_state: "Maryland",
  },
  {
    country_code: "BG",
    latitude: "42.733883",
    longitude: "25.48583",
    country: "Bulgaria",
    usa_state_code: "ME",
    usa_state_latitude: "45.253783",
    usa_state_longitude: "-69.445469",
    usa_state: "Maine",
  },
  {
    country_code: "BH",
    latitude: "25.930414",
    longitude: "50.637772",
    country: "Bahrain",
    usa_state_code: "MI",
    usa_state_latitude: "44.314844",
    usa_state_longitude: "-85.602364",
    usa_state: "Michigan",
  },
  {
    country_code: "BI",
    latitude: "-3.373056",
    longitude: "29.918886",
    country: "Burundi",
    usa_state_code: "MN",
    usa_state_latitude: "46.729553",
    usa_state_longitude: "-94.6859",
    usa_state: "Minnesota",
  },
  {
    country_code: "BJ",
    latitude: "9.30769",
    longitude: "2.315834",
    country: "Benin",
    usa_state_code: "MO",
    usa_state_latitude: "37.964253",
    usa_state_longitude: "-91.831833",
    usa_state: "Missouri",
  },
  {
    country_code: "BM",
    latitude: "32.321384",
    longitude: "-64.75737",
    country: "Bermuda",
    usa_state_code: "MS",
    usa_state_latitude: "32.354668",
    usa_state_longitude: "-89.398528",
    usa_state: "Mississippi",
  },
  {
    country_code: "BN",
    latitude: "4.535277",
    longitude: "114.727669",
    country: "Brunei",
    usa_state_code: "MT",
    usa_state_latitude: "46.879682",
    usa_state_longitude: "-110.362566",
    usa_state: "Montana",
  },
  {
    country_code: "BO",
    latitude: "-16.290154",
    longitude: "-63.588653",
    country: "Bolivia",
    usa_state_code: "NC",
    usa_state_latitude: "35.759573",
    usa_state_longitude: "-79.0193",
    usa_state: "North Carolina",
  },
  {
    country_code: "BR",
    latitude: "-14.235004",
    longitude: "-51.92528",
    country: "Brazil",
    usa_state_code: "ND",
    usa_state_latitude: "47.551493",
    usa_state_longitude: "-101.002012",
    usa_state: "North Dakota",
  },
  {
    country_code: "BS",
    latitude: "25.03428",
    longitude: "-77.39628",
    country: "Bahamas",
    usa_state_code: "NE",
    usa_state_latitude: "41.492537",
    usa_state_longitude: "-99.901813",
    usa_state: "Nebraska",
  },
  {
    country_code: "BT",
    latitude: "27.514162",
    longitude: "90.433601",
    country: "Bhutan",
    usa_state_code: "NH",
    usa_state_latitude: "43.193852",
    usa_state_longitude: "-71.572395",
    usa_state: "New Hampshire",
  },
  {
    country_code: "BV",
    latitude: "-54.423199",
    longitude: "3.413194",
    country: "Bouvet Island",
    usa_state_code: "NJ",
    usa_state_latitude: "40.058324",
    usa_state_longitude: "-74.405661",
    usa_state: "New Jersey",
  },
  {
    country_code: "BW",
    latitude: "-22.328474",
    longitude: "24.684866",
    country: "Botswana",
    usa_state_code: "NM",
    usa_state_latitude: "34.97273",
    usa_state_longitude: "-105.032363",
    usa_state: "New Mexico",
  },
  {
    country_code: "BY",
    latitude: "53.709807",
    longitude: "27.953389",
    country: "Belarus",
    usa_state_code: "NV",
    usa_state_latitude: "38.80261",
    usa_state_longitude: "-116.419389",
    usa_state: "Nevada",
  },
  {
    country_code: "BZ",
    latitude: "17.189877",
    longitude: "-88.49765",
    country: "Belize",
    usa_state_code: "NY",
    usa_state_latitude: "43.299428",
    usa_state_longitude: "-74.217933",
    usa_state: "New York",
  },
  {
    country_code: "CA",
    latitude: "56.130366",
    longitude: "-106.346771",
    country: "Canada",
    usa_state_code: "OH",
    usa_state_latitude: "40.417287",
    usa_state_longitude: "-82.907123",
    usa_state: "Ohio",
  },
  {
    country_code: "CC",
    latitude: "-12.164165",
    longitude: "96.870956",
    country: "Cocos [Keeling] Islands",
    usa_state_code: "OK",
    usa_state_latitude: "35.007752",
    usa_state_longitude: "-97.092877",
    usa_state: "Oklahoma",
  },
  {
    country_code: "CD",
    latitude: "-4.038333",
    longitude: "21.758664",
    country: "Congo [DRC]",
    usa_state_code: "OR",
    usa_state_latitude: "43.804133",
    usa_state_longitude: "-120.554201",
    usa_state: "Oregon",
  },
  {
    country_code: "CF",
    latitude: "6.611111",
    longitude: "20.939444",
    country: "Central African Republic",
    usa_state_code: "PA",
    usa_state_latitude: "41.203322",
    usa_state_longitude: "-77.194525",
    usa_state: "Pennsylvania",
  },
  {
    country_code: "CG",
    latitude: "-0.228021",
    longitude: "15.827659",
    country: "Congo [Republic]",
    usa_state_code: "PR",
    usa_state_latitude: "18.220833",
    usa_state_longitude: "-66.590149",
    usa_state: "Puerto Rico",
  },
  {
    country_code: "CH",
    latitude: "46.818188",
    longitude: "8.227512",
    country: "Switzerland",
    usa_state_code: "RI",
    usa_state_latitude: "41.580095",
    usa_state_longitude: "-71.477429",
    usa_state: "Rhode Island",
  },
  {
    country_code: "CI",
    latitude: "7.539989",
    longitude: "-5.54708",
    country: "Côte d'Ivoire",
    usa_state_code: "SC",
    usa_state_latitude: "33.836081",
    usa_state_longitude: "-81.163725",
    usa_state: "South Carolina",
  },
  {
    country_code: "CK",
    latitude: "-21.236736",
    longitude: "-159.777671",
    country: "Cook Islands",
    usa_state_code: "SD",
    usa_state_latitude: "43.969515",
    usa_state_longitude: "-99.901813",
    usa_state: "South Dakota",
  },
  {
    country_code: "CL",
    latitude: "-35.675147",
    longitude: "-71.542969",
    country: "Chile",
    usa_state_code: "TN",
    usa_state_latitude: "35.517491",
    usa_state_longitude: "-86.580447",
    usa_state: "Tennessee",
  },
  {
    country_code: "CM",
    latitude: "7.369722",
    longitude: "12.354722",
    country: "Cameroon",
    usa_state_code: "TX",
    usa_state_latitude: "31.968599",
    usa_state_longitude: "-99.901813",
    usa_state: "Texas",
  },
  {
    country_code: "CN",
    latitude: "35.86166",
    longitude: "104.195397",
    country: "China",
    usa_state_code: "UT",
    usa_state_latitude: "39.32098",
    usa_state_longitude: "-111.093731",
    usa_state: "Utah",
  },
  {
    country_code: "CO",
    latitude: "4.570868",
    longitude: "-74.297333",
    country: "Colombia",
    usa_state_code: "VA",
    usa_state_latitude: "37.431573",
    usa_state_longitude: "-78.656894",
    usa_state: "Virginia",
  },
  {
    country_code: "CR",
    latitude: "9.748917",
    longitude: "-83.753428",
    country: "Costa Rica",
    usa_state_code: "VT",
    usa_state_latitude: "44.558803",
    usa_state_longitude: "-72.577841",
    usa_state: "Vermont",
  },
  {
    country_code: "CU",
    latitude: "21.521757",
    longitude: "-77.781167",
    country: "Cuba",
    usa_state_code: "WA",
    usa_state_latitude: "47.751074",
    usa_state_longitude: "-120.740139",
    usa_state: "Washington",
  },
  {
    country_code: "CV",
    latitude: "16.002082",
    longitude: "-24.013197",
    country: "Cape Verde",
    usa_state_code: "WI",
    usa_state_latitude: "43.78444",
    usa_state_longitude: "-88.787868",
    usa_state: "Wisconsin",
  },
  {
    country_code: "CX",
    latitude: "-10.447525",
    longitude: "105.690449",
    country: "Christmas Island",
    usa_state_code: "WV",
    usa_state_latitude: "38.597626",
    usa_state_longitude: "-80.454903",
    usa_state: "West Virginia",
  },
  {
    country_code: "CY",
    latitude: "35.126413",
    longitude: "33.429859",
    country: "Cyprus",
    usa_state_code: "WY",
    usa_state_latitude: "43.075968",
    usa_state_longitude: "-107.290284",
    usa_state: "Wyoming",
  },
  {
    country_code: "CZ",
    latitude: "49.817492",
    longitude: "15.472962",
    country: "Czech Republic",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "DE",
    latitude: "51.165691",
    longitude: "10.451526",
    country: "Germany",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "DJ",
    latitude: "11.825138",
    longitude: "42.590275",
    country: "Djibouti",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "DK",
    latitude: "56.26392",
    longitude: "9.501785",
    country: "Denmark",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "DM",
    latitude: "15.414999",
    longitude: "-61.370976",
    country: "Dominica",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "DO",
    latitude: "18.735693",
    longitude: "-70.162651",
    country: "Dominican Republic",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "DZ",
    latitude: "28.033886",
    longitude: "1.659626",
    country: "Algeria",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "EC",
    latitude: "-1.831239",
    longitude: "-78.183406",
    country: "Ecuador",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "EE",
    latitude: "58.595272",
    longitude: "25.013607",
    country: "Estonia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "EG",
    latitude: "26.820553",
    longitude: "30.802498",
    country: "Egypt",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "EH",
    latitude: "24.215527",
    longitude: "-12.885834",
    country: "Western Sahara",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ER",
    latitude: "15.179384",
    longitude: "39.782334",
    country: "Eritrea",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ES",
    latitude: "40.463667",
    longitude: "-3.74922",
    country: "Spain",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ET",
    latitude: "9.145",
    longitude: "40.489673",
    country: "Ethiopia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "FI",
    latitude: "61.92411",
    longitude: "25.748151",
    country: "Finland",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "FJ",
    latitude: "-16.578193",
    longitude: "179.414413",
    country: "Fiji",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "FK",
    latitude: "-51.796253",
    longitude: "-59.523613",
    country: "Falkland Islands [Islas Malvinas]",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "FM",
    latitude: "7.425554",
    longitude: "150.550812",
    country: "Micronesia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "FO",
    latitude: "61.892635",
    longitude: "-6.911806",
    country: "Faroe Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "FR",
    latitude: "46.227638",
    longitude: "2.213749",
    country: "France",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GA",
    latitude: "-0.803689",
    longitude: "11.609444",
    country: "Gabon",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GB",
    latitude: "55.378051",
    longitude: "-3.435973",
    country: "United Kingdom",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GD",
    latitude: "12.262776",
    longitude: "-61.604171",
    country: "Grenada",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GE",
    latitude: "42.315407",
    longitude: "43.356892",
    country: "Georgia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GF",
    latitude: "3.933889",
    longitude: "-53.125782",
    country: "French Guiana",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GG",
    latitude: "49.465691",
    longitude: "-2.585278",
    country: "Guernsey",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GH",
    latitude: "7.946527",
    longitude: "-1.023194",
    country: "Ghana",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GI",
    latitude: "36.137741",
    longitude: "-5.345374",
    country: "Gibraltar",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GL",
    latitude: "71.706936",
    longitude: "-42.604303",
    country: "Greenland",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GM",
    latitude: "13.443182",
    longitude: "-15.310139",
    country: "Gambia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GN",
    latitude: "9.945587",
    longitude: "-9.696645",
    country: "Guinea",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GP",
    latitude: "16.995971",
    longitude: "-62.067641",
    country: "Guadeloupe",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GQ",
    latitude: "1.650801",
    longitude: "10.267895",
    country: "Equatorial Guinea",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GR",
    latitude: "39.074208",
    longitude: "21.824312",
    country: "Greece",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GS",
    latitude: "-54.429579",
    longitude: "-36.587909",
    country: "South Georgia and the South Sandwich Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GT",
    latitude: "15.783471",
    longitude: "-90.230759",
    country: "Guatemala",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GU",
    latitude: "13.444304",
    longitude: "144.793731",
    country: "Guam",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GW",
    latitude: "11.803749",
    longitude: "-15.180413",
    country: "Guinea-Bissau",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GY",
    latitude: "4.860416",
    longitude: "-58.93018",
    country: "Guyana",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "GZ",
    latitude: "31.354676",
    longitude: "34.308825",
    country: "Gaza Strip",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "HK",
    latitude: "22.396428",
    longitude: "114.109497",
    country: "Hong Kong",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "HM",
    latitude: "-53.08181",
    longitude: "73.504158",
    country: "Heard Island and McDonald Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "HN",
    latitude: "15.199999",
    longitude: "-86.241905",
    country: "Honduras",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "HR",
    latitude: "45.1",
    longitude: "15.2",
    country: "Croatia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "HT",
    latitude: "18.971187",
    longitude: "-72.285215",
    country: "Haiti",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "HU",
    latitude: "47.162494",
    longitude: "19.503304",
    country: "Hungary",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ID",
    latitude: "-0.789275",
    longitude: "113.921327",
    country: "Indonesia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IE",
    latitude: "53.41291",
    longitude: "-8.24389",
    country: "Ireland",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IL",
    latitude: "31.046051",
    longitude: "34.851612",
    country: "Israel",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IM",
    latitude: "54.236107",
    longitude: "-4.548056",
    country: "Isle of Man",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IN",
    latitude: "20.593684",
    longitude: "78.96288",
    country: "India",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IO",
    latitude: "-6.343194",
    longitude: "71.876519",
    country: "British Indian Ocean Territory",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IQ",
    latitude: "33.223191",
    longitude: "43.679291",
    country: "Iraq",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IR",
    latitude: "32.427908",
    longitude: "53.688046",
    country: "Iran",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IS",
    latitude: "64.963051",
    longitude: "-19.020835",
    country: "Iceland",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "IT",
    latitude: "41.87194",
    longitude: "12.56738",
    country: "Italy",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "JE",
    latitude: "49.214439",
    longitude: "-2.13125",
    country: "Jersey",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "JM",
    latitude: "18.109581",
    longitude: "-77.297508",
    country: "Jamaica",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "JO",
    latitude: "30.585164",
    longitude: "36.238414",
    country: "Jordan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "JP",
    latitude: "36.204824",
    longitude: "138.252924",
    country: "Japan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KE",
    latitude: "-0.023559",
    longitude: "37.906193",
    country: "Kenya",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KG",
    latitude: "41.20438",
    longitude: "74.766098",
    country: "Kyrgyzstan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KH",
    latitude: "12.565679",
    longitude: "104.990963",
    country: "Cambodia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KI",
    latitude: "-3.370417",
    longitude: "-168.734039",
    country: "Kiribati",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KM",
    latitude: "-11.875001",
    longitude: "43.872219",
    country: "Comoros",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KN",
    latitude: "17.357822",
    longitude: "-62.782998",
    country: "Saint Kitts and Nevis",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KP",
    latitude: "40.339852",
    longitude: "127.510093",
    country: "North Korea",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KR",
    latitude: "35.907757",
    longitude: "127.766922",
    country: "South Korea",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KW",
    latitude: "29.31166",
    longitude: "47.481766",
    country: "Kuwait",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KY",
    latitude: "19.513469",
    longitude: "-80.566956",
    country: "Cayman Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "KZ",
    latitude: "48.019573",
    longitude: "66.923684",
    country: "Kazakhstan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LA",
    latitude: "19.85627",
    longitude: "102.495496",
    country: "Laos",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LB",
    latitude: "33.854721",
    longitude: "35.862285",
    country: "Lebanon",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LC",
    latitude: "13.909444",
    longitude: "-60.978893",
    country: "Saint Lucia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LI",
    latitude: "47.166",
    longitude: "9.555373",
    country: "Liechtenstein",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LK",
    latitude: "7.873054",
    longitude: "80.771797",
    country: "Sri Lanka",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LR",
    latitude: "6.428055",
    longitude: "-9.429499",
    country: "Liberia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LS",
    latitude: "-29.609988",
    longitude: "28.233608",
    country: "Lesotho",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LT",
    latitude: "55.169438",
    longitude: "23.881275",
    country: "Lithuania",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LU",
    latitude: "49.815273",
    longitude: "6.129583",
    country: "Luxembourg",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LV",
    latitude: "56.879635",
    longitude: "24.603189",
    country: "Latvia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "LY",
    latitude: "26.3351",
    longitude: "17.228331",
    country: "Libya",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MA",
    latitude: "31.791702",
    longitude: "-7.09262",
    country: "Morocco",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MC",
    latitude: "43.750298",
    longitude: "7.412841",
    country: "Monaco",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MD",
    latitude: "47.411631",
    longitude: "28.369885",
    country: "Moldova",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ME",
    latitude: "42.708678",
    longitude: "19.37439",
    country: "Montenegro",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MG",
    latitude: "-18.766947",
    longitude: "46.869107",
    country: "Madagascar",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MH",
    latitude: "7.131474",
    longitude: "171.184478",
    country: "Marshall Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MK",
    latitude: "41.608635",
    longitude: "21.745275",
    country: "Macedonia [FYROM]",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ML",
    latitude: "17.570692",
    longitude: "-3.996166",
    country: "Mali",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MM",
    latitude: "21.913965",
    longitude: "95.956223",
    country: "Myanmar [Burma]",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MN",
    latitude: "46.862496",
    longitude: "103.846656",
    country: "Mongolia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MO",
    latitude: "22.198745",
    longitude: "113.543873",
    country: "Macau",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MP",
    latitude: "17.33083",
    longitude: "145.38469",
    country: "Northern Mariana Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MQ",
    latitude: "14.641528",
    longitude: "-61.024174",
    country: "Martinique",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MR",
    latitude: "21.00789",
    longitude: "-10.940835",
    country: "Mauritania",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MS",
    latitude: "16.742498",
    longitude: "-62.187366",
    country: "Montserrat",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MT",
    latitude: "35.937496",
    longitude: "14.375416",
    country: "Malta",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MU",
    latitude: "-20.348404",
    longitude: "57.552152",
    country: "Mauritius",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MV",
    latitude: "3.202778",
    longitude: "73.22068",
    country: "Maldives",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MW",
    latitude: "-13.254308",
    longitude: "34.301525",
    country: "Malawi",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MX",
    latitude: "23.634501",
    longitude: "-102.552784",
    country: "Mexico",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MY",
    latitude: "4.210484",
    longitude: "101.975766",
    country: "Malaysia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "MZ",
    latitude: "-18.665695",
    longitude: "35.529562",
    country: "Mozambique",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NA",
    latitude: "-22.95764",
    longitude: "18.49041",
    country: "Namibia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NC",
    latitude: "-20.904305",
    longitude: "165.618042",
    country: "New Caledonia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NE",
    latitude: "17.607789",
    longitude: "8.081666",
    country: "Niger",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NF",
    latitude: "-29.040835",
    longitude: "167.954712",
    country: "Norfolk Island",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NG",
    latitude: "9.081999",
    longitude: "8.675277",
    country: "Nigeria",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NI",
    latitude: "12.865416",
    longitude: "-85.207229",
    country: "Nicaragua",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NL",
    latitude: "52.132633",
    longitude: "5.291266",
    country: "Netherlands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NO",
    latitude: "60.472024",
    longitude: "8.468946",
    country: "Norway",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NP",
    latitude: "28.394857",
    longitude: "84.124008",
    country: "Nepal",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NR",
    latitude: "-0.522778",
    longitude: "166.931503",
    country: "Nauru",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NU",
    latitude: "-19.054445",
    longitude: "-169.867233",
    country: "Niue",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "NZ",
    latitude: "-40.900557",
    longitude: "174.885971",
    country: "New Zealand",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "OM",
    latitude: "21.512583",
    longitude: "55.923255",
    country: "Oman",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PA",
    latitude: "8.537981",
    longitude: "-80.782127",
    country: "Panama",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PE",
    latitude: "-9.189967",
    longitude: "-75.015152",
    country: "Peru",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PF",
    latitude: "-17.679742",
    longitude: "-149.406843",
    country: "French Polynesia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PG",
    latitude: "-6.314993",
    longitude: "143.95555",
    country: "Papua New Guinea",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PH",
    latitude: "12.879721",
    longitude: "121.774017",
    country: "Philippines",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PK",
    latitude: "30.375321",
    longitude: "69.345116",
    country: "Pakistan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PL",
    latitude: "51.919438",
    longitude: "19.145136",
    country: "Poland",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PM",
    latitude: "46.941936",
    longitude: "-56.27111",
    country: "Saint Pierre and Miquelon",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PN",
    latitude: "-24.703615",
    longitude: "-127.439308",
    country: "Pitcairn Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PR",
    latitude: "18.220833",
    longitude: "-66.590149",
    country: "Puerto Rico",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PS",
    latitude: "31.952162",
    longitude: "35.233154",
    country: "Palestinian Territories",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PT",
    latitude: "39.399872",
    longitude: "-8.224454",
    country: "Portugal",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PW",
    latitude: "7.51498",
    longitude: "134.58252",
    country: "Palau",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "PY",
    latitude: "-23.442503",
    longitude: "-58.443832",
    country: "Paraguay",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "QA",
    latitude: "25.354826",
    longitude: "51.183884",
    country: "Qatar",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "RE",
    latitude: "-21.115141",
    longitude: "55.536384",
    country: "Réunion",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "RO",
    latitude: "45.943161",
    longitude: "24.96676",
    country: "Romania",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "RS",
    latitude: "44.016521",
    longitude: "21.005859",
    country: "Serbia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "RU",
    latitude: "61.52401",
    longitude: "105.318756",
    country: "Russia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "RW",
    latitude: "-1.940278",
    longitude: "29.873888",
    country: "Rwanda",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SA",
    latitude: "23.885942",
    longitude: "45.079162",
    country: "Saudi Arabia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SB",
    latitude: "-9.64571",
    longitude: "160.156194",
    country: "Solomon Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SC",
    latitude: "-4.679574",
    longitude: "55.491977",
    country: "Seychelles",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SD",
    latitude: "12.862807",
    longitude: "30.217636",
    country: "Sudan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SE",
    latitude: "60.128161",
    longitude: "18.643501",
    country: "Sweden",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SG",
    latitude: "1.352083",
    longitude: "103.819836",
    country: "Singapore",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SH",
    latitude: "-24.143474",
    longitude: "-10.030696",
    country: "Saint Helena",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SI",
    latitude: "46.151241",
    longitude: "14.995463",
    country: "Slovenia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SJ",
    latitude: "77.553604",
    longitude: "23.670272",
    country: "Svalbard and Jan Mayen",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SK",
    latitude: "48.669026",
    longitude: "19.699024",
    country: "Slovakia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SL",
    latitude: "8.460555",
    longitude: "-11.779889",
    country: "Sierra Leone",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SM",
    latitude: "43.94236",
    longitude: "12.457777",
    country: "San Marino",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SN",
    latitude: "14.497401",
    longitude: "-14.452362",
    country: "Senegal",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SO",
    latitude: "5.152149",
    longitude: "46.199616",
    country: "Somalia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SR",
    latitude: "3.919305",
    longitude: "-56.027783",
    country: "Suriname",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ST",
    latitude: "0.18636",
    longitude: "6.613081",
    country: "São Tomé and Príncipe",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SV",
    latitude: "13.794185",
    longitude: "-88.89653",
    country: "El Salvador",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SY",
    latitude: "34.802075",
    longitude: "38.996815",
    country: "Syria",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "SZ",
    latitude: "-26.522503",
    longitude: "31.465866",
    country: "Swaziland",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TC",
    latitude: "21.694025",
    longitude: "-71.797928",
    country: "Turks and Caicos Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TD",
    latitude: "15.454166",
    longitude: "18.732207",
    country: "Chad",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TF",
    latitude: "-49.280366",
    longitude: "69.348557",
    country: "French Southern Territories",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TG",
    latitude: "8.619543",
    longitude: "0.824782",
    country: "Togo",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TH",
    latitude: "15.870032",
    longitude: "100.992541",
    country: "Thailand",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TJ",
    latitude: "38.861034",
    longitude: "71.276093",
    country: "Tajikistan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TK",
    latitude: "-8.967363",
    longitude: "-171.855881",
    country: "Tokelau",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TL",
    latitude: "-8.874217",
    longitude: "125.727539",
    country: "Timor-Leste",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TM",
    latitude: "38.969719",
    longitude: "59.556278",
    country: "Turkmenistan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TN",
    latitude: "33.886917",
    longitude: "9.537499",
    country: "Tunisia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TO",
    latitude: "-21.178986",
    longitude: "-175.198242",
    country: "Tonga",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TR",
    latitude: "38.963745",
    longitude: "35.243322",
    country: "Turkey",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TT",
    latitude: "10.691803",
    longitude: "-61.222503",
    country: "Trinidad and Tobago",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TV",
    latitude: "-7.109535",
    longitude: "177.64933",
    country: "Tuvalu",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TW",
    latitude: "23.69781",
    longitude: "120.960515",
    country: "Taiwan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "TZ",
    latitude: "-6.369028",
    longitude: "34.888822",
    country: "Tanzania",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "UA",
    latitude: "48.379433",
    longitude: "31.16558",
    country: "Ukraine",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "UG",
    latitude: "1.373333",
    longitude: "32.290275",
    country: "Uganda",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "UM",
    latitude: "",
    longitude: "",
    country: "U.S. Minor Outlying Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "US",
    latitude: "37.09024",
    longitude: "-95.712891",
    country: "United States",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "UY",
    latitude: "-32.522779",
    longitude: "-55.765835",
    country: "Uruguay",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "UZ",
    latitude: "41.377491",
    longitude: "64.585262",
    country: "Uzbekistan",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "VA",
    latitude: "41.902916",
    longitude: "12.453389",
    country: "Vatican City",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "VC",
    latitude: "12.984305",
    longitude: "-61.287228",
    country: "Saint Vincent and the Grenadines",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "VE",
    latitude: "6.42375",
    longitude: "-66.58973",
    country: "Venezuela",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "VG",
    latitude: "18.420695",
    longitude: "-64.639968",
    country: "British Virgin Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "VI",
    latitude: "18.335765",
    longitude: "-64.896335",
    country: "U.S. Virgin Islands",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "VN",
    latitude: "14.058324",
    longitude: "108.277199",
    country: "Vietnam",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "VU",
    latitude: "-15.376706",
    longitude: "166.959158",
    country: "Vanuatu",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "WF",
    latitude: "-13.768752",
    longitude: "-177.156097",
    country: "Wallis and Futuna",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "WS",
    latitude: "-13.759029",
    longitude: "-172.104629",
    country: "Samoa",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "XK",
    latitude: "42.602636",
    longitude: "20.902977",
    country: "Kosovo",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "YE",
    latitude: "15.552727",
    longitude: "48.516388",
    country: "Yemen",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "YT",
    latitude: "-12.8275",
    longitude: "45.166244",
    country: "Mayotte",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ZA",
    latitude: "-30.559482",
    longitude: "22.937506",
    country: "South Africa",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ZM",
    latitude: "-13.133897",
    longitude: "27.849332",
    country: "Zambia",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
  {
    country_code: "ZW",
    latitude: "-19.015438",
    longitude: "29.154857",
    country: "Zimbabwe",
    usa_state_code: "",
    usa_state_latitude: "",
    usa_state_longitude: "",
    usa_state: "",
  },
];

export const bandDef = [
  { band: "2200m", low: 0.1357, high: 0.1378 },
  { band: "160m", low: 1.8, high: 2 },
  { band: "80m", low: 3.5, high: 4 },
  { band: "40m", low: 7, high: 7.3 },
  { band: "30m", low: 10.1, high: 10.15 },
  { band: "20m", low: 14, high: 14.35 },
  { band: "17m", low: 18.068, high: 18.168 },
  { band: "15m", low: 21, high: 21.45 },
  { band: "12m", low: 24.89, high: 24.99 },
  { band: "10m", low: 28, high: 29.7 },
  { band: "6m", low: 50, high: 54 },
  { band: "2m", low: 144, high: 148 },
  { band: "1.35m", low: 222, high: 225 },
  { band: "70cm", low: 430, high: 450 },
];
